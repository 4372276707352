import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Button from '@mui/material/Button';
import LayoutAuthSub from '../../components/Layout/LayoutAuthSub'
import strings from "./../../components/Language";
//  CSS
import './../../assets/styles/style.scss';
import './forgotpassword.scss';
import logo from './../../assets/images/logo.png';

import { Form, Input } from 'antd';
import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import _ from 'lodash';
class ForgotPasswordScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            password_confirmation: "",
            verification_code: "",
            mobile: "",
            otp_code: "",
            redirect: false,
        }
    }

    async componentDidMount() {
    }

    componentWillUnmount() {
    }

    onFinish(values) {
        var self = this;
        axios({
            method: 'POST',
            url: api.forgot_password,
            data: values,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            if (ress.data && ress.data.error === 200) {
                toast.success(ress.data.message);
                self.props.history.replace({ pathname: `/login` })
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {   
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (e) {
            //toast.error(e.message);
        });
    };

    sendCode() {
        var self = this;
        var json = { email: self.state.email }
        axios({
            method: 'POST',
            url: api.forgot_password_send_code,
            data: json,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            if (ress.data && ress.data.error === 200) {
                self.setState({ otp_code: ress.data.data.otp_code })
                toast.success(ress.data.message);
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {   
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (e) {
            //toast.error(e.message);
        });
    };

    onChangeEmail = (event, selectedDate) => {
        var self = this;
        self.setState({ email: event.target.value })
    };

    render() {
        if (this.state.redirect) {
            return <Link to="/register-successful" />;
        }
        return (
            <LayoutAuthSub data={{ "title": strings.forgot_password }}>
                <div className="card card-style mb-0 bg-transparent shadow-0 bg-3 mx-0 rounded-0 main_height auth_page" data-card-height="cover"
                    style={{ marginTop: 30 }}
                >
                    <div className="card-center" style={{ marginBottom: 30 }}>
                        <div className="card card-style">
                            <div className="content" style={{ margin: 20 }}>
                                <div className="col text-center">
                                    <img data-src="images/pictures/23t.jpg" className="preload-img image-logo rounded-xs entered loaded" alt="img" src={logo} data-ll-status="loaded" />
                                </div>

                                <Form
                                    className='offset-md-4 col-md-4'
                                    name="sign-in"
                                    onFinish={(data) => this.onFinish(data)}
                                >
                                    <div>
                                        <label className="">{strings.email}</label>
                                        <div className="form-custom form-label form-icon mb-3">
                                            <Form.Item
                                                label="Email"
                                                name="email"
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        message: strings.email_not_valid,
                                                    },
                                                    {
                                                        required: true,
                                                        message: strings.email_input,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    onChange={this.onChangeEmail}
                                                    className="form-control rounded-xs"
                                                    placeholder="Enter Email"
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: 10 }}>
                                        <label className="">{strings.verification_code}</label>
                                        <div className='row'>
                                            <div className='col-9'>
                                                <div className="form-custom form-label form-icon mb-3">
                                                    <Form.Item
                                                        name="verification_code"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: strings.verification_code_required,
                                                            },
                                                            // ({ getFieldValue }) => ({
                                                            //     validator(rule, value) {
                                                            //         if (!value || self.state.otp_code == value) {
                                                            //             return Promise.resolve();
                                                            //         }
                                                            //         return Promise.reject(strings.otp_not_match);
                                                            //     },
                                                            // }),
                                                        ]}
                                                    >
                                                        <Input
                                                            className="form-control rounded-xs"
                                                            placeholder={strings.verification_code_enter}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-3 p-0'>
                                            <Button className='mt-1' variant="outlined" onClick={(data) => this.sendCode()} style={{ padding: 5,textTransform:"none" }}>{strings.get_code}</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: 10 }}>
                                        <label className="">{strings.password}</label>
                                        {/* <div className="form-custom form-label form-icon mb-3"> */}
                                        <div>
                                            <Form.Item
                                                name="password"
                                                rules={[
                                                    { required: true, message: strings.password_input },
                                                ]}
                                            >
                                                <Input.Password
                                                    className="form-control rounded-xs password-class"
                                                    placeholder={strings.password_enter}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: 10 }}>
                                        <label className="">{strings.retype_password}</label>
                                        <div>
                                            <Form.Item
                                                name="password_confirmation"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: strings.retype_password_input
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            if (!value || getFieldValue('password') == value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(strings.retype_password_not_match);
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password
                                                    className="form-control rounded-xs password-class"
                                                    placeholder={strings.retype_password_enter}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <Form.Item>
                                        <Button type="primary" className='btn rounded-sm btn-m login_button text-uppercase font-700 mt-4 mb-3 btn-full'
                                            style={{ fontSize: 14, paddingBlock: 10, background: '#446CE3', color: "#FFF", width: "100%", borderRadius:10 }}>
                                            {strings.submit}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutAuthSub>


        );
    }
}

export default withRouter(ForgotPasswordScreen);
