import React from 'react';
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout';
import strings from "./../../components/Language";

import SliderHome from "./Slider/index";
import Menu from "./Menu";
import DCABot from "./ListItems/DCABot";
import BOLLBot from "./ListItems/BOLLBot";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import "./home.scss";

import ArrowForword from './../../assets/images/icons/arrow_forword.svg';
import notifications from './../../assets/images/menu/notification.png';

import { getToken, storeUserData, getUserData } from './../../components/Util';

// import { toast } from 'react-toastify';

class HomeScreen extends React.Component {
	constructor(props) {
		super(props);
		var headerData = (this.props && this.props.data) ? this.props.data : {};
		var headerTitle = (this.props && this.props.data && this.props.data.title) ? this.props.data.title : "";
		this.state = {
			headerData: headerData,
			headerTitle: headerTitle,
			user_data: {},
			tabValue: "DCABot"
		}
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event, newValue) {
		const self = this;
		self.setState({ tabValue: newValue });
	};

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			setTimeout(() => {
				var user_data = getUserData();
				this.setState({ access_token: token, user_data: (user_data && user_data.data) ? user_data.data : {} });
			});
		}
	}

	render() {
		return (
			<Layout data={{ "title": strings.Home, is_logo: true }}>
				<div className="slider_class">
					<SliderHome />
				</div>
				<div className='border_'></div>
				<Link to="/announcement" className='flex-center' style={{ justifyContent: "space-between", padding: 10 }}>
					<div className='flex-center' style={{ flexDirection: "row" }}>
						<img className="" alt="Notifications" src={notifications} width={25} height={25} />
						<div style={{ marginLeft: 10 }}>
							<span style={{ fontSize: 14 }}>{strings.Latest_Announcement}</span>
						</div>
					</div>
					<div>
						<img src={ArrowForword} alt="ArrowForword" width={15} height={15}></img>
					</div>
				</Link>
				<div className='border_'></div>
				<div className="">
					<Menu />
				</div>
				<div className='border_'></div>
				<div className="">
					<Tabs
						value={this.state.tabValue}
						onChange={this.handleChange}
						variant="scrollable"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
					>
						<Tab label="DCA" value={"DCABot"} />
						<Tab label="BOLL" value={"BOLLBot"} />
					</Tabs>
					<div role="tabpanel" hidden={this.state.tabValue !== "DCABot"}>
						{this.state.tabValue === "DCABot" ?
							<div className="">
								<DCABot />
							</div>
							: null}
					</div>
					<div role="tabpanel" hidden={this.state.tabValue !== "BOLLBot"}>
						{this.state.tabValue === "BOLLBot" ?
							<div className="">
								<BOLLBot />
							</div>
							: null}
					</div>
				</div>
			</Layout>
		);
	}
}

export default HomeScreen;
