import React from 'react'
import SubLayout from '../../components/Layout/SubLayout'
import strings from "../../components/Language";
import _ from 'lodash';

//  CSS
import './../../assets/styles/style.scss';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData, getUserData } from './../../components/Util';

import Modal from '@mui/material/Modal';
import { QrReader } from 'react-qr-reader';

class ConvertScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            access_token: "",
            username: "",
            amount: '',
            verification_code: '',
            otp_code: '',
            user_data: {},
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            setTimeout(() => {
                this.setState({
                    access_token: token
                });
                var rowData = getUserData();
                if (rowData.data) {
                    this.setState({
                        user_data: rowData.data
                    });
                }
            });
        }
    }

    sendData() {
        var self = this;
        if (self.state.access_token) {

            var json = {
                amount: self.state.amount
            }

            self.setState({ loading: true });
            axios({
                method: 'POST',
                url: api.convert_credit,
                data: json,
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {
                    toast.success(ress.data.message);
                    self.props.history.replace({ pathname: `/gas-history` })
                } else {
                    if (ress.data.validation && ress.data.validation.length !== 0) {
                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                toast.error(value[0]);
                            }
                        });
                    } else {
                        toast.error(ress.data.message);
                    }
                }
                self.setState({ loading: false });
            }).catch(function (e) {
                self.setState({ loading: false });
            });
        }
    };

    render() {

        return (
            <>
                <SubLayout ref={this.wrapper} data={{ "title": strings.convert }}>
                    <div className='offset-md-4 col-md-4' style={{ marginTop: 20, paddingInline: 5 }}>
                        <div className="text-right">
                            <Link to={"/gas-history"} style={{ marginRight: 10 }} >{strings.gas_fees_details}</Link>
                        </div>

                        <div className="list_item" style={{ padding: 0, paddingBlock: 10, borderRadius: 10 }}>

                            <div className='row' style={{ margin: 10, color: "#8E8E8E" }}>
                                <div className='col-12' style={{ marginTop: 0 }}>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.convert_quantity}</label>
                                </div>
                                <div className='col-12 flex-center' style={{
                                    justifyContent: "space-between", background: "#07051F",
                                    padding: 10, marginTop: 10, borderRadius: 10
                                }}>
                                    <div className='text_box_main'>
                                        <input
                                            name='amount'
                                            onChange={e => this.setState({ amount: e.target.value })}
                                            value={this.state.amount}
                                            className="form-control rounded-xs text_box"
                                            placeholder="Minimum Withdraw Amount"
                                        />
                                    </div>
                                    <div onClick={() => this.setState({ amount: (this.state.user_data && this.state.user_data.credit && this.state.user_data.credit.usdt) ? parseFloat(this.state.user_data.credit.usdt).toFixed(5) : 0.00 })} style={{ marginInline: 10, color: "#446CE3", fontSize: 14, cursor: "pointer" }}>
                                        {strings.All}
                                    </div>
                                </div>
                            </div>

                            <div style={{ margin: 10, padding: 10, color: "#8E8E8E" }}>{strings.Available}: {(this.state.user_data && this.state.user_data.credit && this.state.user_data.credit.usdt) ? parseFloat(this.state.user_data.credit.usdt).toFixed(5) : "0"} {strings.usdt}</div>
                        </div>
                        <div className='border_'></div>

                        <div className="col text-center" style={{ marginTop: 20 }}>
                            <Button className='btn rounded-sm btn-m login_button text-uppercase font-700 mt-4 mb-3 btn-full' onClick={() => this.sendData()} style={{ fontSize: 14, paddingBlock: 10, background: '#446CE3', color: "#FFF", width: "100%", borderRadius: 10, cursor: "pointer", opacity: (this.state.loading) ? 0.7 : 1, }} loading={this.state.loading}>{(this.state.loading) ? strings.loading : strings.confirm_conver}</Button>
                        </div>

                    </div>
                </SubLayout>
            </>
        );
    }
}

export default ConvertScreen;
