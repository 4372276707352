import React from 'react';
import SubLayout from '../../components/Layout/SubLayout';
import strings from "./../../components/Language";

import { Button } from 'antd';

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData } from './../../components/Util';
import _ from 'lodash';

import Copy from './../../assets/images/icons/copy.png';

class BindingScreen extends React.Component {
    constructor(props) {
        super(props);
        var params_id = this.props.match.params.id;
        this.state = {
            loading: false,
            data: this.props.location.data,
            access_key: (this.props.location.data && this.props.location.data.data && this.props.location.data.data.access_key) ? this.props.location.data.data.access_key : "",
            secret_key: (this.props.location.data && this.props.location.data.data && this.props.location.data.data.secret_key) ? this.props.location.data.data.secret_key : "",
            verification_code: '',
            otp_code: '',
            platform: params_id,
            params_id: params_id,
            already_bind: true,
            is_load: true,
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            setTimeout(() => {
                this.setState({ access_token: token });
                this.getData();
            }, 1000);
        }
    }

    sendCode() {
        var self = this;
        if (self.state.access_token) {
            axios({
                method: 'POST',
                url: api.bind_trading_platform_send_code,
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {
                    self.setState({ otp_code: ress.data.data.otp_code });
                } else {
                    if (ress.data.validation && ress.data.validation.length !== 0) {
                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                toast.error(value[0]);
                            }
                        });
                    } else {
                        toast.error(ress.data.message);
                    }
                }
            }).catch(function (e) {
                //toast.error(e.message);
            });
        }
    };

    getData() {
        var self = this;
        if (self.state.access_token) {
            axios({
                method: 'GET',
                url: api.bind_trading_platform,
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {
                    var resData = ress.data.data;
                    var dataFilter = _.find(resData, function (obj) {
                        return obj.id == self.state.params_id;
                    });
                    if (dataFilter) {
                        self.setState({
                            data: dataFilter,
                            access_key: (dataFilter.data && dataFilter.data.access_key) ? dataFilter.data.access_key : "",
                            secret_key: (dataFilter.data && dataFilter.data.secret_key) ? dataFilter.data.secret_key : "",
                            already_bind: (dataFilter.data && dataFilter.data.length !== 0) ? true : false,
                            is_load: false,
                        });
                    } else {
                        self.props.history.replace({ pathname: `/apibind` })
                    }
                } else {
                    if (ress.data.validation && ress.data.validation.length !== 0) {
                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                toast.error(value[0]);
                            }
                        });
                    } else {
                        toast.error(ress.data.message);
                    }
                }
            }).catch(function (e) {
                //toast.error(e.message);
            });
        }
    };

    bindData() {
        var self = this;
        if (self.state.access_token) {
            
            self.setState({ loading: true });

            var json = {
                access_key: self.state.access_key,
                secret_key: self.state.secret_key,
                platform: self.state.platform,
                verification_code: self.state.verification_code,
            }

            axios({
                method: 'POST',
                url: api.bind_trading_platform_store,
                data: json,
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {
                    self.props.history.replace({ pathname: `/apibind` })
                } else {
                    if (ress.data.validation && ress.data.validation.length !== 0) {
                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                toast.error(value[0]);
                            }
                        });
                    } else {
                        toast.error(ress.data.message);
                    }
                }
                self.setState({ loading: false });
            }).catch(function (e) {
                //toast.error(e.message);
                self.setState({ loading: false });
            });
        }
    };

    unBindData() {
        var self = this;
        self.setState({ loading: true });
        if (self.state.access_token) {
            var json = {
                platform: self.state.params_id
            }
            axios({
                method: 'POST',
                url: api.bind_trading_platform_delete,
                data: json,
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {
                    toast.success(ress.data.message);
                    self.getData();
                } else {
                    if (ress.data.validation && ress.data.validation.length !== 0) {
                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                toast.error(value[0]);
                            }
                        });
                    } else {
                        toast.error(ress.data.message);
                    }
                }
                self.setState({ loading: false });
            }).catch(function (e) {
                //toast.error(e.message);
                self.setState({ loading: false });
            });
        }
    };

    copyText(text) {
        navigator.clipboard.writeText(text);
        toast.success(strings.Copy_IP_address_msg);
    }

    render() {
        const self = this;
        return (
            <>
                {!this.state.is_load ?
                    <SubLayout data={{ "title": (self.state.data && self.state.data.name) ? self.state.data.name + " " + strings.binding : "" }}>
                        <div className="list_item" style={{ padding: 0, paddingBlock: 10 }}>
                            <div className='row' style={{ margin: 10, marginTop: 20 }}>
                                <div className='col-8' style={{ marginTop: 0 }}>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{(self.state.data && self.state.data.name) ? self.state.data.name + " " + strings.binding + " " + strings.percautions : strings.percautions}</label>
                                </div>
                                <div className='col-4' style={{ marginTop: 0, textAlign: "right" }}>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.see_the_instructions}</label>
                                </div>
                            </div>
                            <div className='row' style={{ margin: 10, color: "#8E8E8E" }}>
                                <div className='col-12' style={{ marginTop: 0 }}>
                                    {strings.see_the_instructions_point_1}
                                </div>
                                <div className='col-12' style={{ marginTop: 0 }}>
                                    {strings.see_the_instructions_point_2}
                                </div>
                                <div className='col-12' style={{ marginTop: 0 }}>
                                    {/* {strings.see_the_instructions_point_3} */}
                                    {"3. Trusted IP: 192.53.114.180, 159.223.89.232, 172.104.189.243, 139.162.49.244, 192.53.118.188, 172.104.160.250, 172.104.172.247, 172.104.34.131, 172.104.181.81, 172.104.181.239, 139.162.56.75, 118.107.235.48"}
                                    <Button
                                        type="button" className='button rounded-sm btn-m login_button text-uppercase font-300 btn-full'
                                        // onClick={() => this.copyCode()}
                                        onClick={() => this.copyText("192.53.114.180 159.223.89.232 172.104.189.243 139.162.49.244 192.53.118.188 172.104.160.250 172.104.172.247 172.104.34.131 172.104.181.81 172.104.181.239 139.162.56.75 118.107.235.48")}
                                        style={{ borderRadius: 5, marginLeft: 5, paddingBlock: 5, paddingInline: 10, cursor: "pointer", lineHeight: 1, fontSize: 12 }}
                                    >
                                        <img src={Copy} alt="Copy" width={12} height={12}></img> {strings.Copy_IP_address}
                                        </Button>
                                </div>
                            </div>
                        </div>
                        {this.state.already_bind ?
                            <div className="list_item" style={{ padding: 0, paddingBlock: 10 }}>
                                <div className='row' style={{ margin: 10, marginTop: 20 }}>
                                    <div className='col-12' style={{ marginTop: 0 }}>
                                        <div style={{ color: "#FFFFFF", fontSize: 14, marginRight: 15 }}>{strings.access_key}</div>
                                        <label style={{ color: "#8E8E8E", fontSize: 14, width: "100%" }}>
                                            <div className="form-control rounded-xs" style={{ width: "100%", fontSize: 12 }}>{this.state.access_key.substr(this.state.access_key.length - 10).replace(/.(?=.{5})/g, 'x')}</div>
                                        </label>
                                    </div>
                                </div>
                                <div className='row' style={{ margin: 10, marginTop: 20 }}>
                                    <div className='col-12' style={{ marginTop: 0 }}>
                                        <div style={{ color: "#FFFFFF", fontSize: 14, marginRight: 15 }}>{strings.secret_key}</div>
                                        <label style={{ color: "#8E8E8E", fontSize: 14, width: "100%" }}>
                                            <div className="form-control rounded-xs" style={{ width: "100%", fontSize: 12 }}>{this.state.secret_key.substr(this.state.secret_key.length - 10).replace(/.(?=.{5})/g, 'x')}</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="list_item" style={{ margin: 10, paddingBlock: 10 }}>
                                <div className='row' style={{}}>
                                    <div className='col-12' style={{ marginTop: 0 }}>
                                        <div className='' style={{ color: "#FFFFFF", fontSize: 14, marginRight: 15 }}>{strings.access_key}</div>
                                        <div className="form-custom form-label form-icon mb-3">
                                            <input
                                                name='access_key'
                                                onChange={e => this.setState({ access_key: e.target.value })}
                                                value={this.state.access_key}
                                                className="form-control rounded-xs"
                                                placeholder={strings.access_key_enter}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row' style={{}}>
                                    <div className='col-12' style={{ marginTop: 0 }}>
                                        <div className='' style={{ color: "#FFFFFF", fontSize: 14, marginRight: 15 }}>{strings.secret_key}</div>
                                        <div className="form-custom form-label form-icon mb-3">
                                            <input
                                                name='secret_key'
                                                onChange={e => this.setState({ secret_key: e.target.value })}
                                                value={this.state.secret_key}
                                                className="form-control rounded-xs"
                                                placeholder={strings.secret_key_enter}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='row' style={{}}>
                                    <label className="">{strings.verification_code}</label>
                                    <div className='row'>
                                        <div className='col-8 col-md-11 col-px-2'>
                                            <div className="form-custom form-label form-icon mb-3">
                                                <input
                                                    type="text"
                                                    name='verification_code'
                                                    onChange={e => this.setState({ verification_code: e.target.value })}
                                                    value={this.state.verification_code}
                                                    className="form-control rounded-xs"
                                                    placeholder={strings.verification_code_enter}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 col-md-1 col-px-2'>
                                            <Button
                                                loading={this.state.loading_code}
                                                variant="outlined"
                                                onClick={(data) => this.sendCode()}
                                                style={{
                                                    opacity: this.state.loading_code ? 0.7 : 1, padding: 5, borderWidth: 1, borderColor: "#446CE3", borderRadius: 5,
                                                    background: "transparent", color: "#FFF", marginTop: 5
                                                }}>
                                                {this.state.loading_code ? strings.loading : strings.send_code}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {this.state.already_bind ?
                            <div style={{ padding: 10 }}>
                                <Button loading={this.state.loading} onClick={() => this.unBindData()} type="primary" className='btn rounded-sm btn-m login_button text-uppercase font-700 mt-4 mb-3 btn-full'
                                    style={{ opacity: (this.state.loading) ? 0.7 : 1, fontSize: 14, paddingBlock: 10, background: '#446CE3', color: "#FFF", width: "100%", borderRadius: 10 }}>
                                    {(this.state.loading) ? strings.loading : strings.unbind}
                                </Button>
                            </div>
                            :
                            <div style={{ padding: 10 }}>
                                <Button loading={this.state.loading} onClick={() => this.bindData()} type="primary" className='btn rounded-sm btn-m login_button text-uppercase font-700 mt-4 mb-3 btn-full'
                                    style={{ opacity: (this.state.loading) ? 0.7 : 1, fontSize: 14, paddingBlock: 10, background: '#446CE3', color: "#FFF", width: "100%", borderRadius: 10 }}>
                                    {(this.state.loading) ? strings.loading : strings.bind_now}
                                </Button>
                            </div>
                        }
                    </SubLayout >
                    : null
                }
            </>
        );
    }
}

export default BindingScreen;