import React from 'react'
import { Helmet } from 'react-helmet';
class LayoutAuth extends React.Component {
  constructor(props) {
    super(props);
    var headerTitle = (this.props && this.props.data && this.props.data.title) ? this.props.data.title : "";
    this.state = {
      headerTitle: headerTitle,
    }
  }
  render() {
    return (
      <>
        <Helmet>
          <title>CTSBot | {this.state.headerTitle}</title>
        </Helmet>
        <div
          className="page-content mb-0 pb-0"
        >
          {this.props.children}
        </div>
      </>
    );
  }
}
export default LayoutAuth
