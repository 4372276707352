import React from 'react';
import { Link } from 'react-router-dom'
import strings from "../../../components/Language/index.js";

import './listitems.scss';

import Bitcoin from './../../../assets/images/icons/bitcoin.svg';

import axios from 'axios';
import { api } from '../../../components/config/api.js';
import { getToken, storeUserData } from '../../../components/Util/index.js';
import _ from 'lodash';

import { io } from "socket.io-client";
class DCABot extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            access_token: "",
            // botLiveData: [],
            data: [],
            pariNames: "",
            get_live_bot: false,
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            setTimeout(() => {
                this.setState({ access_token: token });
                this.getData();
            });

            try {
                // var self = this;
                // var socket = io.connect('https://price.benchmarkbot.io');
                // socket.on('connect', function (data) {
                // });
                // socket.on('price', function (data) {
                //     console.log("-------------", data);
                //     self.setState({
                //         botLiveData: data,
                //         get_live_bot: true,
                //     })
                //     // self.getData();
                // });
            }
            catch (e) {
            }
        }
    }

    getData() {
        var self = this;
        if (self.state.access_token) {
            axios({
                method: 'GET',
                url: api.bot_report_active,
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {

                    var parinames = "";
                    var botData = _.filter(ress.data.data, function (o) {
                        o.average_ = o.total_usdt_qty / o.total_coin_qty;
                        o.is_calculate = (o.total_coin_qty > 0) ? true : false;

                        if (o.pair.name) {
                            if (parinames) {
                                parinames += `,`;
                            }
                            parinames += `"` + o.pair.name + `"`;
                        }

                        return o;
                    });
                    self.setState({ data: botData, pariNames: parinames });

                    if (parinames && parinames.length != 0) {
                        self.getLiveData();
                        setInterval(() => {
                            self.getLiveData();
                        }, 10000);
                    }

                }
            }).catch(function (e) {
            });
        }
    };

    getLiveData() {
        var self = this;
        if (self.state.access_token) {

            // var API = encodeURI("https://api.binance.com/api/v3/ticker/24hr?symbols=" + self.state.pariNames);
            var API = "https://api.binance.com/api/v3/ticker/24hr?symbols=[" + self.state.pariNames + "]";
            axios({
                method: 'GET',
                url: API,
            }).then(function (ress) {
                if (ress && ress.status == 200) {
                    _.filter(self.state.data, function (o) {
                        var finalLiveData = _.find(ress.data, { symbol: o.pair.name });
                        console.log("finalLiveData::::::::::::::", finalLiveData);
                        if (finalLiveData) {
                            o.liveData = finalLiveData;
                            o.lastPrice = finalLiveData.lastPrice;
                        }
                        return o;
                    })
                    self.setState({ get_live_bot: true });
                }
                self.setState({ is_load: false });
            }).catch(function (e) {
            });
        }
    };

    render() {
        return (
            <>
                {
                    this.state.data.length === 0 ? <h5 className='text-danger text-center mt-2'>{strings.no_record_found}</h5> : null
                }
                {
                    this.state.data.map((item, i) =>
                        <div key={i + 901} className="list_item">
                            <Link to={{ pathname: `/dca-bot/${item._id}` }}>
                                <div className="row">
                                    <div className='col-8 flex-center'>
                                        <img className="image_icon" src={(item.pair && item.pair.icon_url) ? item.pair.icon_url : Bitcoin} alt="Bitcoin" width={25} height={25} />
                                        <div className='width-auto margin-left-1'>
                                            <span className=''>{(item.pair && item.pair.name) ? item.pair.name : ''}</span>
                                        </div>
                                        <div className='cycle margin-left-1'>
                                            <label className=''>{(item.type) ? strings.cycle : strings.one_shot}</label>
                                        </div>
                                    </div>
                                    <div className='col-4 avg_label'>
                                        <div className='width-fit-content avg_price'>
                                            <label className={(((item.lastPrice - item.average_)) > 0) ? "text_up" : "text_down"}>
                                                {item.is_calculate ?
                                                    (this.state.get_live_bot) ? Number.parseFloat(((item.lastPrice - item.average_) * 100 / item.average_)).toFixed(2) : "0"
                                                    : "0"}%
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row list_details gray_color" style={{ fontSize: 12 }}>
                                    <div className='col-6'>
                                        <div className='width-auto margin-left-1'>
                                            <label>{strings.quantity}: <span className=''>{(item.total_coin_qty) ? Number.parseFloat(item.total_coin_qty).toFixed(5) : '0'}</span></label>
                                        </div>
                                        <div className='width-auto margin-left-1'>
                                            <label>{strings.latest_price}: <span className=''>
                                                {(this.state.get_live_bot) ? Number.parseFloat(item.lastPrice).toFixed(5) : "0.00"}
                                            </span></label>
                                        </div>
                                    </div>
                                    <div className='col-6 text-right'>
                                        <div className='width-auto margin-left-1'>
                                            <label>{strings.floating_profit}: <span className=''>
                                                {item.is_calculate ?
                                                    <>
                                                        {((item.total_coin_qty * item.lastPrice) - (item.total_coin_qty * item.average_) != "NaN") ?
                                                            (this.state.get_live_bot) ? Number.parseFloat((item.total_coin_qty * item.lastPrice) - (item.total_coin_qty * item.average_)).toFixed(5) : "0.00"
                                                            :
                                                            "0.00"
                                                        }
                                                    </>
                                                    :
                                                    "0.00"
                                                }
                                            </span></label>
                                        </div>
                                        <div className='width-auto margin-left-1'>
                                            <label>{strings.increase}:
                                                <span className={(item.liveData && item.liveData.priceChangePercent && item.liveData.priceChangePercent > 0) ? "text_up" : "text_down"}>
                                                    {item.liveData && item.liveData.priceChangePercent ?
                                                        Number.parseFloat(item.liveData.priceChangePercent).toFixed(2)
                                                        : "0"}%
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                }
            </>
        );
    }
}

export default DCABot;
