import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import strings from '../../components/Language';

import LayoutAuth from '../../components/Layout/LayoutAuth'
//  CSS
import './../../assets/styles/style.scss';
import './login.scss';
import logo from './../../assets/images/logo.png';

import { Form, Input, Button } from 'antd';

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import _ from 'lodash';
import LayoutAuthSub from '../../components/Layout/LayoutAuthSub.js';
class LoginScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
        }
    }

    onFinish(values) {
        var self = this;
        var json = {
            email: values.email,
            password: values.password,
        }
        axios({
            method: 'POST',
            url: api.login,
            data: json,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(async function (ress) {
            if (ress.data && ress.data.error === 200) {
                await localStorage.setItem('user_data', JSON.stringify((ress.data.data && ress.data.data.user) ? ress.data.data.user : []));
                await localStorage.setItem('user_token', (ress.data.data && ress.data.data.token) ? ress.data.data.token : '');
                toast.success(ress.data.message);
                self.props.history.go('/home');
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
                localStorage.clear();
            }
        }).catch(function (e) {
            localStorage.clear();
        });
    };

    render() {
        return (
            <LayoutAuthSub data={{ "title": strings.login }}>
                <div className="card card-style mb-0 bg-transparent shadow-0 bg-3 mx-0 rounded-0 main_height auth_page" data-card-height="cover" style={{ marginTop: 30 }}>
                    <div className="card-center">
                        <div className="card card-style">
                            <div className="content " style={{ margin: 20 }}>
                                <div className="col text-center">
                                    <img className="preload-img image-logo rounded-xs entered loaded" alt="img" src={logo} data-ll-status="loaded" />
                                    <h1 className='text-center'>{strings.sign_in}</h1>
                                </div>

                                <Form
                                    className='offset-md-4 col-md-4'
                                    name="sign-in"
                                    onFinish={(data) => this.onFinish(data)}
                                >
                                    <div>
                                        <label className="">{strings.email_address}</label>
                                        <div className="form-custom form-label form-icon mb-3">
                                            <Form.Item
                                                name="email"
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        message: strings.email_not_valid,
                                                    },
                                                    {
                                                        required: true,
                                                        message: strings.email_input,
                                                    },
                                                ]}
                                            >
                                                <Input className="form-control rounded-xs" placeholder={strings.email_address_enter} />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div style={{ marginBottom: 10 }}>
                                        <label className="">{strings.password}</label>
                                        <div>
                                            <Form.Item
                                                name="password"
                                                rules={[
                                                    { required: true, message: strings.password_input },
                                                ]}
                                            >
                                                <Input.Password
                                                    className="form-control rounded-xs password-class"
                                                    placeholder={strings.password_enter}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className='forgot-password'>
                                        <Link to="/forgot-password" style={{ fontSize: 14 }}>{strings.forgot_password}</Link>
                                    </div>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className='btn rounded-sm btn-m login_button text-uppercase font-700 mt-4 mb-3 btn-full '
                                            style={{ fontSize: 14, paddingBlock: 10, width: "100%", borderRadius: 10 }}>
                                            {strings.login}
                                        </Button>
                                    </Form.Item>

                                    <div className='register_text' style={{ fontSize: 14 }}>
                                        {strings.not_register_yet}
                                        <Link to="/register" className='register_link' style={{ marginLeft: 10 }}>{strings.register_here}</Link>
                                    </div>
                                </Form>

                            </div>
                        </div>
                    </div>
                </div>
            </LayoutAuthSub>


        );
    }
}

export default withRouter(LoginScreen);
