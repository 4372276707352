export default {

    ctcbot: "ctcbot",

    // Menu
    Home: "主页",
    Quatitative: "量化",
    Community: "社区",
    Notifications: "通告",
    Profile: "我的",

    api_bind: "API 绑定",
    invite_friend: "邀请好友",
    wallet: "钱包",
    rewards: "收益",

    //Login
    login: "登录",
    sign_in: "登录中",
    register_here: "这里注册",
    not_register_yet: "还没注册?",
    email: "邮箱",
    email_enter: "输入邮箱",
    email_address: "邮箱地址",
    email_address_enter: "输入邮箱地址",
    forgot_password: "忘记密码",
    email_input: "请输入您的邮箱!",
    email_not_valid: "邮箱输入不正确!",
    password: "密码",
    password_enter: "输入密码",
    password_input: "请输入您的密码!",
    retype_password: "重试密码",
    retype_password_enter: "重新输入密码",
    retype_password_input: "请重新输入您的密码!",
    retype_password_not_match: "您所输入的密码不正确!",

    // Register
    register: "注册",
    sign_up: "加入",
    please_input_your_username: "请输入您的昵称!",
    enter_username: "输入昵称",
    invitation_code: "邀请码",
    invitation_code_enter: "输入邀请码",
    invitation_code_required: "请输入您的邀请码!",
    register_now: "即刻注册",
    congratulations: "恭喜",
    register_successful: "注册成功",

    // Common
    Coming_Soon: "敬请期待!",
    loading: "加载中...",
    submit: "提交",
    refresh: "更新",
    add: "添加",
    confirm: "确定",
    logout: "退出",
    done: "确认",
    login_now: "现在登录",
    no_records_found: "没发现记录!",
    save: "储存",
    Load_More: "加载更多",
    we_could_not_find: "找不到",
    page: "界面",
    USD: "美金",
    english: "英文",
    malaysia: "马来西亚",
    chinese: "简体中⽂",

    // Verification Code...
    verification_code: "验证码",
    verification_code_enter: "请输入验证码",
    verification_code_required: "验证码栏目需填写.",
    verification_code_not_valid: "您输入的验证码不正确! 请输入正确的验证码.",
    input_your_verification_code: "请输入您的验证码",
    otp_not_match: "OTP代码不正确!",
    send_code: "发送代码",
    get_code: "获取代码",

    profile: "个人简介",
    id: "ID",
    affiliate_program: "市场计划",
    team: "团队",
    activate: "启动",
    not_activated_yet: "尚未激活",
    activate_profit: "激活盈利",
    activate_profit_today: "今日利润",
    activate_profit_total: "总共利润",
    direct_quantification: "直属奖金",
    team_quantification: "团队奖金",
    direct_referrals: "直接推荐",
    grade: "阶级",
    account: "户口",
    number_of_team: "团队人数",
    registration_time: "注册时间",
    reward_details: "收益明细",
    full_details: "完整资料",
    bot_quantification: "激活奖⾦",
    profit: "利润",
    pair: "币种",
    description: "描述",
    profit_quantification: "团队奖⾦",
    total_profit_today: "今日总利润",
    total_profit_earned: "总利润",
    share: "分享",
    helpline: "客服中心",
    account_problem: "户口问题",
    trading_problem: "交易问题",
    suggestion_opinions: "提供建议",
    report_violation: "举报违规行为",
    money_flow_problem: "资金流动问题",
    api_binding_issues: "API 绑定问题",
    bug_problem: "错误问题",
    other: "其他",
    security_centre: "安全中心",
    my_policy_settings: "我的政策设定",
    system_setting: "系统设置",
    change_email: "更换邮箱",
    new_email_enter: "请输入新的邮箱",
    new_email_verification_code: "新邮箱验证码",
    new_email_verification_code_enter: "输入新邮箱验证码",
    safety_email_verification_Code: "邮箱安全验证码",
    please_enter_the_email_verification_code: "请输入邮箱验证码",
    change_password: "更换密码",
    enter_old_password: "请输入旧密码",
    input_your_old_password: "请输入您的旧密码!",
    please_enter_password: "请输入密码",
    please_enter_retype_password: "请重新输入密码",
    delete_account: "删除账户",
    delete_account_note: "注意账号被删除后，信息会被清空，无法找回.",
    delete_account_following_conditions: "以下情况不能删除账号:",
    delete_account_following_conditions_1: "- 账户有错误.",
    delete_account_following_conditions_2: "- 同步政策尚未取消.",
    delete_account_following_conditions_3: "- 所有交易策略尚未结束.",
    delete_account_following_conditions_4: "- API 绑定还未解绑的.",
    profile_information: "档案信息",
    change_avatar: "更换头像",
    change_profile_image: "更换档案照片",
    name: "名字",
    username: "昵称",
    my_inviter_id: "我的介绍人 ID",
    level: "等级",
    status: "当前状态",
    language: "语言",
    transaction_password: "转账密码",
    new_password: "新密码",
    please_enter_new_password: "请输入新密码",
    Please_input_your_new_password: "请输入您的新密码!",
    google_verification_code: "谷歌验证码",
    withdraw_address_management: "提币地址管理",
    bank_management: "银行管理",
    touch_id_pin: "触碰 ID & PIN",
    valuation_method: "计价⽅式",
    theme_mode: "主题界面",
    dark_mode: "夜间模式",
    firmware_version: "版本更新",
    Latest_Announcement: "最新通告",
    Announcement_Detail: "公告详情",
    binding: "绑定中",
    percautions: "预防措施",
    see_the_instructions: "请参阅说明",
    see_the_instructions_point_1: "1. 请确认API权限已勾选“启用现货&保证金交易”",
    see_the_instructions_point_2: "2. 请输入正确的API，请勿输入特殊字符.",
    see_the_instructions_point_3: "3. Trusted IP: 118.107.235.48",
    access_key: "API Key",
    access_key_enter: "请输入API Key",
    secret_key: "密钥",
    secret_key_enter: "请输入密钥",
    access_key_required: "API key 栏目需要填写.",
    secret_key_required: "需填写密钥.",
    bound: "绑定",
    unbound: "未绑定",
    unbind: "解绑",
    bind_now: "立即绑定",
    member_center: "会员中心",
    current_level_lower_info: "当前级别低于此级别",
    level_membership_info: "此级别会员权限不可用，请升级您的级别!",
    B0: "B0",
    no_record_found: "找不到记录",
    cycle: "循环模式",
    one_shot: "单次策略",
    quantity: "数量",
    latest_price: "最新价位",
    floating_profit: "浮动盈亏",
    increase: "增加",
    code_copy: "复制代码",
    code_copy_seccessfully: "复制代码成功!",
    copy_link: "复制链接",
    link_copy_seccessfully: "复制链接成功!",
    my_invitation_code: "我的邀请码",
    my_policy_setting: "我的政策设置",
    no_notifications: "没有通知",
    logs: "历史记录",
    no_logs: "找不到历史记录",
    market: "市场",
    amount: "数量",
    price: "价位",
    fee: "费用",
    total: "总共",
    your_account_not_activated_now: "您的户口尚未激活，请现在激活您的户口!",
    id_not_found: "没找到 ID !",
    BUY: "买入",
    are_you_sure_BUY: "确定要买入吗?",
    SELL: "卖出",
    are_you_sure_SELL: "确定要卖出吗?",
    usdt: "USDT",
    enter_USDT: "输入USDT",
    USDT_required: "需要提供USDT!",
    trade_bot: "操盘EA 机器⼈",
    position_amount: "持仓金额",
    avg_price: "持仓均价",
    numbers_of_call_margin: "补仓次数",
    position_quantity: "持仓数量",
    current_price: "当前价格",
    return_rate: "收益率",
    first_preset_buy_in_price: "当前预设价格",
    first_buy_amount: "首单额度",
    margin_call_limit: "补仓上限",
    take_profit_ratio: "止盈比例",
    take_profit_call_back: "盈利回调",
    buy_in_callback: "补仓回调",
    trade_setting: "交易设置",
    stop: "暂停",
    start: "开始",
    bot_not_found: "没发现EA!",
    margin_configuration: "补仓设置",
    Margin_call_Drop: "补仓跌幅",
    Multiple_Of_Times: "倍数",
    trade_settings: "交易设置",
    first_buy_in_amount: "首单额度",
    open_position_doubled: "双倍开单",
    wallet_address_not_foud: "没找到钱包地址!",
    transfer: "转移",
    transfer_details: "转移记录",
    confirm_transfer: "确认转移",
    deposit: "充币",
    withdraw: "提币",
    deposit_details: "充币记录",
    chain_name: "区块链名称",
    deposit_address: "充币地址",
    USDT_deposit_address: "USDT充币地址",
    address_can_only_receive_USDT: "此地址只可以接收USDT",
    code_link: "代码链接",
    risk_disclaimer: "风险提醒声明",
    deposit_risk_disclaimer_point_1: "1. 请不要将任何非USDT资产存入上述地址，否则资产将无法取回.",
    deposit_risk_disclaimer_point_2: "2. 不接受充值非USDT资产",
    deposit_risk_disclaimer_point_3: "3. 未激活的账户不能提币.",
    withdrawal_risk_disclaimer_point_1: "1. 单次提币最低金额为10USDT",
    withdrawal_risk_disclaimer_point_2: "2. 提币款项将在 24 小时内进行处理.",
    withdrawal_risk_disclaimer_point_3: "3. 请提供正确的TRC20 USDT地址，以免造成资产损失.",
    URI_not_found: "URI 没找到!",
    TXID: "TXID",
    Total_Assets_Converted_USDT: "总资产兑换USDT",
    history_record: "历史记录",
    the_recipient_wallet_username_field_is_required: "收款人钱包用户名字段为必填项.",
    the_amount_field_is_required: "必须填写数额.",
    the_verification_code_field_is_required: "必须填写验证码.",
    verification_code_not_valid_enter_valid: "您输入的验证码不正确! 请输入正确验证码.",
    Receipient_UserID: "收款用户ID",
    transfer_quantity: "转移数额",
    All: "全部",
    Available: "可用的资金",
    Transaction_Fees: "交易费用",
    the_address_field_is_required: "地址栏目必需填写.",
    withdrawal: "提币",
    withdrawal_details: "提币资讯",
    Withdraw_details: "提币记录",
    withdrawal_address: "提币地址",
    withdrawal_address_enter: "请输入你的提币地址",
    withdrawal_quantity: "提币数额",
    minimum_withdraw_amount: "最少提币数额",
    receive_amount: "收到数额",
    confirm_withdrawal: "确定提币",
    Scan_QR_Code: "扫描二维码",
    Add_Bank: "添加银行",
    Add_Bank_input: "请输入您的银行名字!",
    Add_Bank_enter: "请输入银行名字",
    Account_Holder: "户口持有人",
    Account_Holder_input: "请输入您的银行户口持有人!",
    Account_Holder_enter: "请输入银行户口持有人",
    Account_Number: "户口号码",
    Account_Number_input: "请输入您的银行户口号码!",
    Account_Number_enter: "请输入银行户口号码",
    Branch: "分行",
    Branch_input: "请输入您的银行分行!",
    Branch_enter: "请输入银行分行",
    City: "城市",
    City_input: "请输入您的银行所属城市!",
    City_enter: "请输入银行所属城市",
    the_wallet_address_field_is_required: "必须填写钱包地址栏.",
    Add_Wallet: "添加钱包",
    Currency: "货币",
    Mainnet: "主网",
    Address: "地址",
    Wallet_Address: "钱包地址",
    Wallet_Label: "钱包标签",
    Bank_Management: "银行管理",
    Wallet_Management: "钱包管理",
    WhiteList: "白名单",
    Trading_Profit: " 交易盈利",
    Chart: "图表",
    Chart_detail: "图表明细",
    fuel_balance_is_insufficient: "燃料费余额不足，需最低20美金在钱包里",
    view_details: "查看详情",
    view_monthly_report: "查看月度报告",
    total_daily_trading_profit: "每日总交易利润",
    spot_trading: "现货交易",
    Trading_Details: "交易详情",
    bot_reward_details: "BOT 奖励详情",
    Profit_details: "利润详情",
    LiveChatSupport: "实时聊天支持",
    need_to_active_account: "需要先激活账户。",
    whole_position: "全仓",
    distributed_and_take_profit_allocation: "分配和获利分配",
    sub_position: "子-位置",
    sub_position_take_profit_callback: "分仓止盈回调",
    strategy_period: "策略周期",
    order_list: "订单列表",
    sell_all: "全部卖出",
    are_you_sure_SELL_all: "你确定全部卖掉?",
    total_order_number: "所有订单号",
    layer: "层",
    layer_call_limit: "Layer Call Limitt",
    all_layer_buy_in_amount: "All Layer Buy In Amount",
    all_layer_take_profit_ratio: "All Layer Take Profit Ratio",
    all_layer_take_profit_call_back: "All Layer Take Profit Callback",
    all_layer_buy_in_callback: "All Layer Buy In Callback",
    all_layer_margin_call_back: "All Layer Margin Callback",
    are_you_sure_you: "are you sure you",
    order_: "order?",
    CONNECT_TO_ACCESS_HOLDER_AREA: "CONNECT TO ACCESS HOLDER-AREA",
    CONNECT_WALLET: "CONNECT WALLET",
    terms_conditions: "Terms & Conditions",
    terms_conditions_1: "1. Owner required to have at least one ctcbot NFT in the wallet.",
    terms_conditions_2: "2. This is a read-only connection, It will not trigger any blockchain transaction or incur any charge fee.",
    terms_conditions_3: "3. Do not share your Private key. We will never ask your Private key.",
    Copy_IP_address: "Copy IP address",
    Copy_IP_address_msg: "IP address Copied!",
    all_layer_buy_in_ratio: "All Layer Buy In Ratio",
};